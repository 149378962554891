//支付成功页面
<template>
    <div>
        <div style="text-align:center">
            <img src="../assets/paySuccess.png"/>
        </div>

        <div>
            <div class="card-top" style="text-align:center;font-size:20px;font-color:red">
                 <a href="javascript:;"  @click="goMyOrder()">点此跳转</a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

         data: function () {
            return {
                accessToken:null,
                thirdUrl:null,
        }
        },
        computed: {

        },
        beforeCreate() {

        },
        created() {
            var thirdUrlReg = new RegExp("(^|&)thirdUrl=([^&]*)(&|$)");
            var thirdUrlR = window.location.search.substr(1).match(thirdUrlReg);
            console.log(thirdUrlR);
            if(thirdUrlR !== null){
                this.thirdUrl = unescape(thirdUrlR[2]);
            }else{
                var reg = new RegExp("(^|&)accessToken=([^&]*)(&|$)");
                var r = window.location.search.substr(1).match(reg);
                this.accessToken = unescape(r[2]);
                console.log(this.accessToken);
            }
            console.log(this.thirdUrl);
        },

        methods: {
            goMyOrder:function(){
                if (this.thirdUrl !== null) {
                    window.location.href=this.thirdUrl;
                }
                else{
                    this.$router.push({name:'MyOrder',params:{accessToken:this.accessToken}});
                }
            },
        }
    }
</script>




